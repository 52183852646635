.mic-button {
    pointer-events: all;
}

.questions {
    line-height: 1;
    font-size: 20px;
    margin-top: 15px;
}

@media only screen and (min-width: 1080px) {

    .css-oma7rc-MuiAvatar-root {
        min-width: 300px;
        min-height: 300px;
    }

    .css-1pqm26d-MuiAvatar-img {
        width: 180% !important;
        height: 180% !important;
        padding-top: 30px;
    }
}

.css-1pqm26d-MuiAvatar-img {
    width: 100% !important;
    height: 100% !important;
}

.buttons-actions {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 10;
    align-items: flex-end;
    justify-content: center;
    pointer-events: none;
}

.hidden {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 2s, opacity 2s linear;
}

.replay-audio {
    height: 51px !important;
    width: 51px !important;
    pointer-events: all;
    color: rgb(228, 228, 228);
    border: rgb(224, 223, 223) solid 1px;
    background-color: rgba(39, 39, 39, 0.695);
    border-radius: 50%;
    margin-right: 15%;
    margin-bottom: 37px;
}

.pause-audio {
    height: 51px !important;
    width: 51px !important;
    pointer-events: all;
    color: rgb(228, 228, 228);
    border: rgb(224, 223, 223) solid 1px;
    background-color: rgba(39, 39, 39, 0.695);
    border-radius: 50%;
    margin-left: 15%;
    margin-bottom: 37px;
}

.close-audio {
    position: absolute !important;
    color: rgb(255, 255, 255) !important;
    margin-left: 90% !important;
    font-size: 20px !important;
    margin-top: 2% !important;
    z-index: 5;
    width: auto;
}


.close-audio span {
    padding: 4.5px;
}

.loading {
    position: absolute !important;
    height: 200px !important;
    width: 200px !important;
    pointer-events: all;
}

.rce-mbox-text:after {
    display: none;
}