@keyframes animate-fade {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.form-login {
    display: grid !important;
    align-items: end !important;
    justify-items: center !important;
    justify-content: center !important;
    align-content: center !important;
    height: 100vh;
    background-color: #46555a;
    padding: 50px;
}

.form-login p {
    color: rgb(154, 154, 154);
}

.form-login p span {
    color: rgb(255, 255, 255);
    text-decoration: underline;
}

.form-signup {
    display: grid !important;
    align-items: stretch;
    justify-items: center !important;
    justify-content: center !important;
    align-content: stretch;
    height: 100%;
    width: 100%;
    background-color: #46555a;
    padding: 20px;
    position: absolute;
}

.close-terms {
    position: absolute !important;
    position: absolute !important;
    color: rgb(16, 16, 16) !important;
    background: rgba(255, 255, 255, 0.974) !important;
    border-color: rgba(255, 255, 255, 0.137) !important;
    margin-left: 85vw !important;
    margin-right: 0% !important;
    margin-top: -70vh !important;
}

.check {
    color: white !important;
    font-size: 30px !important;
    width: 60px;
    height: 60px;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 1.3s;
    animation-fill-mode: backwards;
}

.check .css-i4bv87-MuiSvgIcon-root {
    width: 1.5em;
    height: 1.5em;
}

.css-ahj2mt-MuiTypography-root {
    font-family: Gilroy-Light !important;
    line-height: 1 !important;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 1.3s;
    animation-fill-mode: backwards;
}

.pass-label {
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.form-login .MuiOutlinedInput-input {
    width: 300px !important;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
    color: white !important;
    background-color: #3335362b !important;
}

.Mui-focused {
    color: white !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgb(255, 255, 255) !important;
    border-width: 2px;
}

.login-btn {
    width: 100%;
    border-radius: 25px !important;
    background-color: rgba(120, 0, 0, 0) !important;
    color: rgb(255, 255, 255) !important;
    border: 1px solid rgba(255, 255, 255, 0.715) !important;
    padding: 10px !important;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.7s;
    animation-fill-mode: backwards;
}

.select-control {
    width: 325px !important;
    padding: 0px !important;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.6s;
    animation-fill-mode: backwards;
    color: white !important;
}

.register-btn {
    width: 100%;
    border-radius: 25px !important;
    background-color: rgba(255, 255, 255, 0) !important;
    color: rgb(255, 255, 255) !important;
    border: none;
    box-shadow: none !important;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 1.0s;
    animation-fill-mode: backwards;
}

.back-btn {
    position: absolute !important;
    border-radius: 25px !important;
    background-color: rgba(120, 0, 0, 0) !important;
    color: rgb(255, 255, 255) !important;
    border: none !important;
    padding: 10px !important;
    box-shadow: none !important;
    animation-duration: 2.0s;
    animation-name: animate-fade;
    animation-delay: 0.1s;
    animation-fill-mode: backwards;
    z-index: 1;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.6s;
    animation-fill-mode: backwards;
    color: white !important;

}

.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    color: white !important;
    font-weight: 200 !important;
}

.form-login label {
    color: white !important;
}

.form-signup label {
    color: white !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
    background-color: rgb(0, 0, 0) !important;
    color: white !important;
}

@media only screen and (max-width: 430px) {

    .logo {
        width: 100%;
    }
}

.button-inquire-hide {
    color: rgb(255, 255, 255) !important;
    margin-top: 40px !important;
}

.logo {
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;
}

h3 {
    text-align: center;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: white !important;

}

.password {
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.step-container {
    height: 100vh;
}

.form-signup .css-1jabshk {
    height: 100% !important;
    max-width: 100%;
    width: 100%;
    padding: 0px 0px;
}

.form-signup .css-zjf1nq-MuiPaper-root {
    display: none !important;
}

.form-signup .css-1x1xx5k {
    height: 100%;
    width: 100%;
    max-width: 100%;
}

.form-signup .css-10asl6r {
    margin-top: -60px;
}

.form-signup .css-kzv9dm {
    width: 100%;
}

.form-signup .title {
    font-size: 25px;
    font-weight: 500;
    margin: 20px 0px 0px 0px;
    padding-top: 10px;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.form-signup p {
    font-size: 18px;
    font-weight: 500;
    margin: 20px 0px;
    color: white;
    line-height: 1em;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0.5s;
    animation-fill-mode: backwards;
}

.form-signup .css-r47a1p-MuiFormControl-root {
    width: 100% !important;
}

.form-signup .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    width: 100% !important;
}

.form-signup .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    width: 100% !important;
}


.form-signup .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    width: 100% !important;
}

.form-signup .css-rh92k-MuiPaper-root-MuiMobileStepper-root {
    margin-top: -60px;
}

.form-signup .chips {
    color: #ffffffbf;
    margin: 3px;
    border-width: 2px;
}

.alert {
    margin: 60% 0px;
    position: absolute !important;
    margin-left: 5%;
    width: 90%;
    z-index: 10000;
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0s;
    animation-fill-mode: backwards;
}

.form-logo {
    animation-duration: 1.0s;
    animation-name: animate-fade;
    animation-delay: 0s;
    animation-fill-mode: backwards;
}

.confirm-pin {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #46555a;
}

@media only screen and (min-width:1200px) {
    .alert {
        margin: 10% 0px;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .terms {
        margin-top: 5% !important;
    }
}

@media only screen and (min-width:720px) {
    .alert {
        margin: 10% auto;
        width: 100%;
        text-align: center;
        justify-content: center;
    }

    .terms {
        margin-top: 5% !important;
    }
}